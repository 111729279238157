import React, { useState } from 'react';
import { Card, Typography, Row, Col, Checkbox, Input } from 'antd';
import './FilterCard.css';
import { difference } from 'lodash';

const { Title } = Typography;

const FilterCard = ({ title, searchPlaceholder, value, options, className, onChange }) => {
  const [search, setSearch] = useState(null);
  const filteredOptions = search ? options.filter(({ label }) => label.toLowerCase().includes(search?.toLowerCase())) : options;

  const onApplyChange = (values) => {
    console.log(values);
    if (values.length > 0) {
      const newValue = difference(values, value);
      console.log(newValue);
      onChange(newValue);
    } else {
      onChange([]);
    }
  };

  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="middle">
          <Col span={24}>
            <Title level={5} className="tic-filter-title">{title}</Title>
          </Col>
          <Col span={24}>
            <Input type="text" placeholder={searchPlaceholder} value={search} onChange={(e) => setSearch(e.target.value)} style={{ marginBottom: 20 }} />
          </Col>
          <Col span={24} style={{ maxHeight: 250, overflowY: 'auto' }}>
            <Checkbox.Group
              className='tic-filter-check-group'
              options={filteredOptions}
              value={value}
              onChange={onApplyChange}
            />
          </Col>
      </Row>
    </Card>
  );
};

export default FilterCard;
