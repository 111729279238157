const { kebabCase, startCase } = require("lodash");

export const getURLString = (val) => {
    return kebabCase(val).toLowerCase();
};

export const getOriginalString = (val) => {
    if (val) {
        return startCase(val.replace('-', ' '));
    }
    return val;
}