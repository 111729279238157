import React from 'react';
import { Avatar, Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Colors } from 'constants/ThemeConstants';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <Result
        icon={<Avatar src={require('assets/images/page-not-found.png')} size={250} />}
        title="Oops!"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" size="large" onClick={() => navigate(-1)} icon={<ArrowLeftOutlined style={{ fontSize: '16px', color: Colors.white }} />}>
            Go back
          </Button>
        }
      />
    </div>
  );
};

export default PageNotFound;
