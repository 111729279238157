import React from 'react';
import { Card, Typography, Row, Col, Image, Button, Avatar, Space, Rate } from 'antd';
import './ListingCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const ListingCard = ({ listing, type }) => {
  const rating = listing?.metadata?.totalRating ? listing?.metadata?.totalRating / listing?.metadata?.totalReviewers : 0;
  const reviews = listing?.metadata?.totalReviewers ?? 0;
  return (
    <Card
      className="tic-listing-card"
    >
      <Row justify="space-between" align="middle">
          {
            listing?.image && (
              <Col span={24}>
                <Image src={`${UPLOAD_URL}/${listing?.image}`} alt={listing?.seometadata?.imageAlt || listing?.name} title={listing?.seometadata?.imageTitle || listing?.name} preview={false} width="100%" className="tic-listing-image" />
              </Col>
            )
          }
          <Col span={24}>
            <Title level={5} className="tic-listing-title">
              <Avatar src={`${UPLOAD_URL}/${listing?.logo}`} alt={listing?.seometadata?.logoAlt || listing?.name} title={listing?.seometadata?.logoTitle || listing?.name} className='tic-listing-logo' />
              {listing?.name}
            </Title>
            <Paragraph className="tic-listing-address">
              <Space>
                <Image src={require('assets/images/icon-map-pin.png')} className='tic-listing-map-icon' />
                <span>
                  {listing?.address?.line1},
                  {listing?.address?.line2 ? `${listing?.address?.line2},` : ''}<br />
                  {listing?.address?.city} - {listing?.address?.pincode}
                </span>
              </Space>
            </Paragraph>
            <Space gap="middle" className="tic-listing-rating-container">
              <Rate disabled defaultValue={rating} />
              <span className="tic-listing-rating-desc">{rating} from {reviews} reviews</span>
            </Space>
          </Col>
          <Col span={24}>
            <Link to={`/business/${listing?.slug}`}>
              <Button type="primary" size="large" className="tic-listing-btn">
                {type === 'owned' ? 'View' : 'Enquire'}
              </Button>
            </Link>
          </Col>
      </Row>
    </Card>
  );
};

export default ListingCard;
