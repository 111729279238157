import React from 'react';
import { Card, Typography, Row, Col, Image, Button } from 'antd';
import './ProductCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';

const { Title, Paragraph } = Typography;

const INR = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumSignificantDigits: 3 });
const ProductCard = ({ product, onEnquire }) => {
  const onSendEnquiry = () => {
    onEnquire(product);
  };

  return (
    <Card
      className="tic-product-card"
    >
      <Row justify="space-between" align="middle">
          {
            product.image && (
              <Col span={24}>
                <Image src={`${UPLOAD_URL}/${product?.image}`} preview={true} width="100%" className="tic-product-image" />
              </Col>
            )
          }
          <Col span={24}>
            <Title level={5} className="tic-product-title">{product?.name}</Title>
            <Paragraph className="tic-product-desc">{product?.description}</Paragraph>
            {
              product?.price && (
                <div style={{ marginTop: 10 }}>
                  <Paragraph className="tic-product-price">{INR.format(product.price || 0)} / Piece</Paragraph>
                </div>
              )
            }
          </Col>
          <Col span={24}>
            <Button type="primary" size="large" className="tic-product-btn" onClick={onSendEnquiry}>
              Enquire
            </Button>
          </Col>
      </Row>
    </Card>
  );
};

export default ProductCard;
