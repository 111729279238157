import { Col, Row, message, List, Breadcrumb, Card } from 'antd';
import axios from 'axios';
import AboutCard from 'components/Cards/AboutCard/AboutCard';
import ProductCard from 'components/Cards/ProductCard/ProductCard';
import { PageNotFound } from 'components/Errors';
import SendEnquiryModal from 'components/Modals/SendEnquiryModal/SendEnquiryModal';
import { PRODUCT_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getBreakPoint } from 'utils/layout';
import { take, filter } from 'lodash';
import ProductListingCard from 'components/Cards/ProductListingViewCard/ProductListingViewCard';

const ViewProductPage = ({ user }) => {
  const isTablet = !getBreakPoint().includes('xl');
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const {bSlug, pSlug} = useParams();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [refreshing, setRefreshing] = useState(true);
  const [productRefreshing, setProductRefreshing] = useState(true);
  const [unlocked, setUnlocked] = useState(false);
  const [enquiryVisible, setEnquiryVisible] = useState(false);

  const onStartEnquiry = () => {
    setEnquiryVisible(true);
  };

  const onCloseEnquiry = () => {
    setEnquiryVisible(false);
  };

  const onSaveEnquiry = () => {
    setUnlocked(true);
    onCloseEnquiry();
  };

  const onOpenProduct = (p) => {
    navigate(`/business/${bSlug}/product/${p.slug}`);
  }

  useEffect(() => {
    onLoadProduct();
  }, [bSlug, pSlug]);

  const onLoadProduct = async () => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${PRODUCT_URL}/${bSlug}/${pSlug}?populate=true`);
      if (res.data.status === 'error') {
        if (res.data.code === 1006) {
          setProduct(null);
        } else {
          message.error(res.data.error);
        }
      } else {
        setProduct(res.data.product);
        onLoadProducts(res.data.product);
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  const onLoadProducts = async (product) => {
    setProductRefreshing(true);
    try {
      const productRes = await axios.get(`${PRODUCT_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({ business: [product?.business?._id], status: ['Active'] }),
        },
      });

      if (productRes.data.status === 'error') {
        message.error(productRes.data.error);
      } else {
        setProducts(take(filter(productRes.data.products, (p) => p._id !== product._id), 3));
      }
    } catch (err) {
      message.error(err.message);
    }
    setProductRefreshing(false);
  };

  if (!product && !refreshing) {
    return (<PageNotFound />);
  }

  return (
    <>
      {
        product && (
          <>
            <Helmet>
              <title>{`${product?.seometadata?.title ?? product?.name}`} | TN Industrial Connect</title>
              <meta name="description" content={product?.seometadata?.description || product?.description} />
              <meta name="keywords" content={product?.seometadata?.keywords} />
              {
                product?.seometadata?.schema && (
                  <script type="application/ld+json">
                    {product?.seometadata?.schema}
                  </script>
                )
              }
            </Helmet>
            <Breadcrumb className="tic-breadcrumb">
              <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to={`/business/${product?.business?.slug}`}>{product?.business?.name}</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to={`/product/${product?.slug}`}>{product?.name}</Link></Breadcrumb.Item>
            </Breadcrumb>
          </>
        )
      }
      <div className='tic-content-wrapper'>
        <Row gutter={[48, 0]}>
          <Col xl={7} lg={10} sm={24}>
            <AboutCard
              userId={user?._id}
              loading={refreshing}
              listing={product?.business}
              unlocked={unlocked}
              onEnquire={() => onStartEnquiry(null)}
            />
          </Col>
          <Col xl={17} lg={14} sm={24}>
            <Card loading={refreshing}>
              {
                product && (
                  <ProductListingCard listing={product} onEnquire={onStartEnquiry} />
                )
              }
              <h3 className="mt-5">Related Products</h3>
              <List
                loading={productRefreshing}
                dataSource={products}
                grid={{ gutter: 24, column: isMobile ? 1 : isTablet ? 2 : 3 }}
                renderItem={(product) => (
                  <List.Item>
                    <ProductCard product={product} onEnquire={onOpenProduct} />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
        <SendEnquiryModal visible={enquiryVisible} listing={product?.business} product={product} user={user} save={onSaveEnquiry} close={onCloseEnquiry} />
      </div>
    </>
  );
};

export default ViewProductPage;
