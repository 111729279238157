import React from 'react';
import { Card, Typography, Space, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import './CityCard.css';
import { getURLString } from 'utils/case-converter';

const { Title } = Typography;

const CityCard = ({ title, cities }) => {
  return (
    <Card className={`tic-city-card`}>
        <Row justify="space-between" align="middle" className='tic-city-card-header'>
            <Col span={14}>
                <Space direction="vertical" size={0}>
                    <Title level={5} className="tic-city-title">{title}</Title>
                </Space>
            </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='tic-city-subcity-list'>
              {
                cities?.map((city) => (
                  <Link to={`/listing/${getURLString(city?.name)}`} className='tic-city-subcity-item'>
                    <FiChevronRight />
                    <span>{city?.name}</span>
                  </Link>
                ))
              }
            </div>
          </Col>
        </Row>
    </Card>
  );
};

export default CityCard;
