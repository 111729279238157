import React from 'react';
import { Button, Col, Image, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import { FiBriefcase, FiPhoneCall, FiSearch } from 'react-icons/fi';
import './Header.css';
import { getBreakPoint } from 'utils/layout';

const Header = () => {
  const isMobile = !getBreakPoint().includes('md');
  return (
    <Row justify="space-between" className='tic-header'>
      <Col>
          <Space align="center">
            <Link to="/">
              <Image src={isMobile ? require('assets/images/logo-sm.png') : require('assets/images/logo.png')} height={isMobile ? 50 : 65}  width={isMobile ? 50 : undefined} preview={false} />
            </Link>
            {
              !isMobile && (
                <Space align="center" className={`tic-header-menu${isMobile ? ' tic-header-sm': ''}`}>
                  <Link to="/">Home</Link>
                  <Link to="/categories">Categories</Link>
                  <Link to="/about">About Us</Link>
                  <Link to="/contact">Contact Us</Link>
                </Space>
              )
            }
          </Space>
      </Col>
      <Col>
        <a href="tel: 9894040277">
          <Button type="secondary" size={isMobile ? 'small' : 'large'} className="mr-2" style={{ backgroundColor: '#468E00', color: '#fff' }}>
            <div className="tic-btn-icon">
              <FiPhoneCall />
              {!isMobile && <span>9894040277</span>}
            </div>
          </Button>
        </a>
        <Link to="/search">
          <Button type="primary" size={isMobile ? 'small' : 'large'} className="mr-2">
            <div className="tic-btn-icon">
              <FiSearch />
              {!isMobile && <span>Search</span>}
            </div>
          </Button>
        </Link>
        {
          !isMobile && (
            <Link to="/contact?type=post-listing">
              <Button  type="primary" size="large" className="dark">
                <div className="tic-btn-icon">
                  <FiBriefcase />
                  <span>Post your listing</span>
                </div>
              </Button>
            </Link>
          )
        }
      </Col>
    </Row>
  );
};

export default Header;
