import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { PageNotFound } from './components/Errors';
import { clearAuth, getAuth, isLoggedIn } from './utils/auth';
import axios from 'axios';
import { Avatar, message } from 'antd';
import { API_VERSION, USER_URL } from './constants/ApiConstants';
import HomePage from 'containers/Pages/Home';
import MainLayout from 'containers/MainLayout';
import ViewListingPage from 'containers/Pages/Listing/View';
import ViewAllListingPage from 'containers/Pages/Listing/ViewAll';
import SearchListingPage from 'containers/Pages/Listing/Search/search';
import IndexableListingPage from 'containers/Pages/Listing/Search/indexable';
import CategoryPage from 'containers/Pages/Category';
import RedirectIf from 'components/UtilComponents/RedirectIf';
import ContactPage from 'containers/Pages/Contact';
import CityPage from 'containers/Pages/Cities';
import AboutPage from 'containers/Pages/About';
import ViewProductPage from 'containers/Pages/Listing/View/product';

const App = () => {
  const auth = getAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(auth != null ? true : false);
  const [user, setUser] = useState(null);

  axios.defaults.headers.common['X-API-Version'] = parseInt(API_VERSION || '0', 10);
  if (auth != null) {
    axios.defaults.headers.common.Authorization = auth.token;
  }

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && 401 === error.response.status) {
          clearAuth();
          setUser(null);
          setTimeout(() => {
            window.location.href = '/';
          }, 200);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  const onLoadUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${USER_URL}/me?populate=true`);
      if (res.data.status === 'success') {
        setUser(res.data.user);
      } else {
        clearAuth();
        setUser(null);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (auth !== null) {
      onLoadUser();
    }
  }, []);

  if (loading) {
    return (
      <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
        <Avatar src={require('./assets/images/logo.png')} style={{ width: 250, height: 60 }} shape="square" />
      </div>
    );
  }

  const ProtectedRoute = ({ state }) => {
    const locatePath = state?.pathname;
    if (locatePath) {
      localStorage.setItem('copiedLink', locatePath);
    }
    return <Navigate to="/" />;
  };

  return (
    <div className="app h-100">
      <Routes>
        {/* ~~~~~ App Routes ~~~~~ */}
        <Route path="/" element={<MainLayout user={user} />}>
          <Route path="/" element={<HomePage user={user} />} />
          <Route path="/categories" element={<CategoryPage />} />
          <Route path="/cities" element={<CityPage />} />
          <Route path="/search" element={<SearchListingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage user={user} />} />
          <Route path="/listing/:city" element={<IndexableListingPage />} />
          <Route path="/listing/:city/:category" element={<IndexableListingPage />} />
          <Route path="/listing/:city/:category/:subCategory" element={<IndexableListingPage />} />
          <Route path="/category/:category" element={<IndexableListingPage />} />
          <Route path="/category/:category/:subCategory" element={<IndexableListingPage />} />
          <Route path="/scategory/:subCategory" element={<IndexableListingPage />} />
          <Route path="/business/:slug" element={<ViewListingPage user={user} />} />
          <Route path="/business/:bSlug/product/:pSlug" element={<ViewProductPage user={user} />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route
          path="/me"
          element={
            <RedirectIf
              condition={isLoggedIn()}
              ifComponent={<MainLayout user={user} />}
              elseComponent={<ProtectedRoute state={location} />}
            />
          }>
          <Route path="/me/listing" element={<ViewAllListingPage user={user} type="owned" />} />
          <Route path="/me/saved/listing" element={<ViewAllListingPage user={user} type="saved" />} />
        </Route>

        {/* ~~~~~ Error Pages ~~~~~ */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
