import React from 'react';
import { Card, Typography, Space, Row, Col, Image } from 'antd';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import './LocationCard.css';
import { getURLString } from 'utils/case-converter';

const { Title } = Typography;

const LocationCard = ({ location }) => {
  return (
    <Link to={location.type === 'view-all' ? '/cities' : `/listing/${getURLString(location?.name)}`}>
      <Card
        className={`tic-location-card ${location.type === 'view-all' ? 'tic-location-view-all' : ''}`}
      >
          <Row justify="space-between" align="middle">
              <Col>
                  <Image src={require('assets/images/icon-location.png')} preview={false} width={40} height={40} />
              </Col>
              <Col span={14}>
                  <Space direction="vertical" size={0}>
                      <Title level={5} className="tic-location-title">{location?.name}</Title>
                      {/* <Paragraph className="tic-location-desc">{location?.businesses} Businesses</Paragraph> */}
                  </Space>
              </Col>
              <Col>
                  <FiArrowRight style={{ fontSize: 24 }} />
              </Col>
          </Row>
      </Card>
    </Link>
  );
};

export default LocationCard;
