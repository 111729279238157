// src/ContactPage.js
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, Space, Card, Divider, Alert, Breadcrumb, message } from 'antd';
import './Contact.css';
import { FiHome, FiMail, FiPhoneCall } from 'react-icons/fi';
import {motion} from 'framer-motion';
import { FORM_SUBMISSION_URL, PAGE_URL } from 'constants/ApiConstants';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const { Title, Text } = Typography;

const ContactPage = ({ user }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'general';
  const [seometadata, setSeometadata] = useState(null);

  useEffect(() => {
    onLoadSEO();
  }, []);

  const onLoadSEO = async () => {
    try {
      const res = await axios.get(`${PAGE_URL}/contact?type=slug`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setSeometadata(res.data.page?.seometadata);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
    })
  }, [user]);

  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    setErrorType(null);
    try {
      const data = {
        type,
        name: values.name,
        emailAddress: values.email,
        phoneNumber: values.phone,
        message: values.message
      };
      const res = await axios.post(`${FORM_SUBMISSION_URL}`, data);
      if (res.data.status === 'error') {
        setError(res.data.error);
        setErrorType('error');
        return setLoading(false);
      }
      setError(`Successfully submitted your query`);
      setErrorType('success');
    } catch (err) {
      setError(err.message);
      setErrorType('error');
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{seometadata?.title ? seometadata?.title : "Contact Us | TN Industrial Connect"}</title>
        <meta name="description" content={seometadata?.description ? seometadata?.description : "Get in touch with TN Industrial Connect. We're here to assist you with any questions or concerns you may have. Reach out to us for support or inquiries"} />
        <meta name="keywords" content={seometadata?.keywords ? seometadata?.keywords : "Contact TN Industrial Connect, Customer Support TN Industrial Connect, Business directory Inquiries in Tamil Nadu, Business Enquiries in TN Industrial Connect"} />
        {
          seometadata?.schema && (
            <script type="application/ld+json">
              {seometadata?.schema}
            </script>
          )
        }
      </Helmet>
      <Breadcrumb className="tic-breadcrumb">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/contact">Contact Us</Link></Breadcrumb.Item>
      </Breadcrumb>
      <div className='tic-content-wrapper'>
        <Title level={1} className='tic-contact-heading-title'>
          Talk to us<br />to get started
        </Title>
        <Typography.Text className='tic-contact-heading-desc'>
          Please provide us with information about your company<br />and we’ll get in touch shortly.
        </Typography.Text>
        <Row gutter={[32, 32]}>
          <Col xs={24} md={12}>
            <Card>
              <Form form={form} layout="vertical" onFinish={onSubmit} disabled={errorType === 'success'}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      whitespace: true,
                      message: 'Please enter your name',
                    },
                  ]}
                  hasFeedback>
                  <Input size="small" placeholder="Name" className='tic-enquiry-modal-input' />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                  hasFeedback>
                  <Input size="small" placeholder="Email Address" className='tic-enquiry-modal-input'  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    },
                    {
                      pattern: /^(\+91|0)?[789]\d{9}$/,
                      message: 'Please enter a valid phone number',
                    },
                  ]}
                  hasFeedback>
                  <Input size="small" placeholder="Phone Number" className='tic-enquiry-modal-input' />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      whitespace: true,
                      message: 'Please enter your message',
                    },
                  ]}
                  hasFeedback>
                  <Input.TextArea size="small" placeholder="Type your message..." className='tic-enquiry-modal-textarea' rows={4} />
                </Form.Item>
                <Form.Item>
                  <Button size="large" type="primary" block htmlType="submit" className="tic-enquiry-modal-btn" loading={loading} disabled={errorType === 'success'}>
                    Submit
                  </Button>
                </Form.Item>
                <motion.div
                  initial={{ opacity: 0, marginBottom: 0 }}
                  animate={{
                    opacity: error ? 1 : 0,
                    marginTop: error ? 20 : 0,
                    marginBottom: error ? 40 : 0,
                  }}>
                  <Alert type={errorType ?? 'error'} showIcon message={error}></Alert>
                </motion.div>
              </Form>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card bodyStyle={{ backgroundColor: "#f1f1f1", padding: '40px 20px' }}>
              <Space direction="vertical" size="large">
                <Title level={2} className='tic-contact-subheading-title'>
                  Looking for quick help or support?
                </Title>
                <Typography.Text className='tic-contact-subheading-desc'>
                  Please reach us through the following email or phone number to connect during business hours
                </Typography.Text>
                <Divider style={{ margin: 0 }} />
                <div className='tic-contact-content'>
                  <FiMail className='tic-contact-icon' />
                  <a href="mailto: contact@tnindustrialconnect.com">
                    <Text className='tic-contact-link'>contact@tnindustrialconnect.com</Text>
                  </a>
                </div>
                <div className='tic-contact-content'>
                  <FiPhoneCall className='tic-contact-icon' />
                  <a href="tel: 9894040277">
                    <Text className='tic-contact-link'>+91 9894040277</Text>
                  </a>
                </div>
                <div className='tic-contact-content'>
                  <FiHome className='tic-contact-icon' />
                  <Text className='tic-contact-link'>
                    No 78, Gopalakrishnapuram, Industrial Estate,<br />Ramakrishna Mill Road, Ganapathy,<br />Coimbatore - 641006
                  </Text>
                </div>
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactPage;
