import { Grid } from 'antd';

const getBreakPoint = () => {
  const screens = Grid.useBreakpoint();
  let breakpoints = [];
  for (const key in screens) {
    if (Object.prototype.hasOwnProperty.call(screens, key)) {
      const element = screens[key];
      if (element) {
        breakpoints.push(key);
      }
    }
  }
  return breakpoints;
};

export { getBreakPoint };
