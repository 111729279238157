import React, { useEffect, useState } from 'react';
import SearchListingComponent from './index';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { CATEGORY_URL, PAGE_URL, SUB_CATEGORY_URL } from 'constants/ApiConstants';
import { Helmet } from 'react-helmet-async';
import { getOriginalString } from 'utils/case-converter';
import { message } from 'antd';

const IndexableListingPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('searchText');
  const city = getOriginalString(params.city);
  const category = params.category;
  const subCategory = params.subCategory;

  const [fPage, setFPage] = useState(null);
  const [fCategory, setFCategory] = useState(null);
  const [fSubCategory, setFSubCategory] = useState(null);
  const slug = window.location.pathname;

  useEffect(() => {
    onLoadData();
  }, [params]);

  useEffect(() => {
    if (slug) {
      onLoadSEO();
    }
  }, [slug]);

  const onLoadSEO = async () => {
    try {
      const res = await axios.get(`${PAGE_URL}`, {
        headers: {
          'X-Api-Filters': JSON.stringify({ slug: [slug] })
        }
      });
      if (res.data.status === 'success' && res.data.pageDocs.length > 0) {
        setFPage(res.data.pageDocs[0]);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onLoadData = async () => {
    setFCategory(null);
    setFSubCategory(null);
    if (category) {
      const categoryRes = await axios.get(`${CATEGORY_URL}/${category}?type=slug`);
      if (categoryRes.data.status === 'success') {
        setFCategory(categoryRes.data.category);
      }
    }
    if (subCategory) {
      const subCategoryRes = await axios.get(`${SUB_CATEGORY_URL}/${subCategory}?type=slug`);
      if (subCategoryRes.data.status === 'success') {
        setFSubCategory(subCategoryRes.data.subcategory);
      }
    }
  };

  const getSEO = () => {
    if (fPage) {
      return {
        title: `${fPage?.seometadata?.title}`,
        description: `${fPage?.seometadata?.description}`,
        keywords: fPage?.seometadata?.keywords,
        schema: fPage?.seometadata?.schema,
      };
    } else if (fCategory && fSubCategory) {
      if (city) {
        return {
          title: `${fSubCategory?.seometadata?.title || fSubCategory?.name} | ${fCategory?.seometadata?.title || fCategory?.name} | ${city} | TN Industrial Connect`,
          description: `${fSubCategory?.seometadata?.description} under ${fCategory?.name} in ${city}`,
          keywords: fSubCategory?.seometadata?.keywords,
          schema: fSubCategory?.seometadata?.schema,
        };
      }
      return {
        title: `${fSubCategory?.seometadata?.title || fSubCategory?.name} | ${fCategory?.seometadata?.title || fCategory?.name} | TN Industrial Connect`,
        description: `${fSubCategory?.seometadata?.description} under ${fCategory?.name}`,
        keywords: fSubCategory?.seometadata?.keywords,
        schema: fSubCategory?.seometadata?.schema,
      };
    } else if (fCategory) {
      if (city) {
        return {
          title: `${fCategory?.seometadata?.title || fCategory?.name} | ${city} | TN Industrial Connect`,
          description: `${fCategory?.seometadata?.description} in ${city}`,
          keywords: fCategory?.seometadata?.keywords,
          schema: fCategory?.seometadata?.schema,
        };
      }
      return {
        title: `${fCategory?.seometadata?.title || fCategory?.name} | TN Industrial Connect`,
        description: `${fCategory?.seometadata?.description}`,
        keywords: fCategory?.seometadata?.keywords,
        schema: fCategory?.seometadata?.schema,
      };
    }
    return {
      title: `Verified Businesses in ${city} | TN Industrial Connect`,
      description: `Discover and connect with verified businesses in ${city}. Browse our directory to find reliable businesses and services in your local area`,
      keywords: `${city} businesses, India Businesses, Tamil Nadu businesses, verified businesses in ${city}, local businesses, TN Industrial Connect`,
    };
  };

  const getType = () => {
    if (city) {
      if (fSubCategory) {
        return 'location-sub-category';
      } else if (fCategory) {
        return 'location-category';
      }
    } else {
      if (fSubCategory) {
        return 'sub-category';
      } else if (fCategory) {
        return 'category';
      }
    }
    return 'location';
  };

  const seo = getSEO();
  return (
    <>
      <Helmet>
        <title>{seo?.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        {
          seo?.schema && (
            <script type="application/ld+json">
              {seo?.schema}
            </script>
          )
        }
      </Helmet>
      <SearchListingComponent
        searchText={searchText}
        city={city ? [city] : []}
        category={fCategory ? [fCategory] : []}
        subCategory={fSubCategory ? [fSubCategory] : []}
        type={getType()}
      />
    </>
  );
};

export default IndexableListingPage;
