import { Breadcrumb, Card, Pagination, message } from 'antd';
import axios from 'axios';
import CategoryCard from 'components/Cards/CategoryCard/CategoryCard';
import { CATEGORY_URL, PAGE_URL, } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import './Category.css';
import { Helmet } from 'react-helmet-async';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Link } from 'react-router-dom';

const CategoryPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [categories, setCategories] = useState([]);
  const [count, setCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [seometadata, setSeometadata] = useState(null);

  useEffect(() => {
    onLoadSEO();
  }, []);

  const onLoadSEO = async () => {
    try {
      const res = await axios.get(`${PAGE_URL}/categories?type=slug`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setSeometadata(res.data.page?.seometadata);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    onLoadCategory();
  }, [page, limit]);

  const onLoadCategory = async () => {
    setRefreshing(true);
    try {
      const xApiFilter = {};
      const res = await axios.get(`${CATEGORY_URL}?page=${page}&limit=${limit}&subCategory=true&populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify(xApiFilter),
        }
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setCategories(res.data.categories);
        setCount(res.data.count);
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };


  return (
    <>
    <Helmet>
      <title>{seometadata?.title ? seometadata?.title : "All Categories | TN Industrial Connect"}</title>
      <meta name="description" content={seometadata?.description ? seometadata?.description : "Explore all business categories on TN Industrial Connect. Find verified businesses across various industries and sectors in Tamil Nadu."} />
      <meta name="keywords" content={seometadata?.keywords ? seometadata?.keywords : "Business categories, Tamil Nadu businesses, TN Industrial Connect Categories, Find businesses by category"} />
      {
        seometadata?.schema && (
          <script type="application/ld+json">
            {seometadata?.schema}
          </script>
        )
      }
    </Helmet>
    <Breadcrumb className="tic-breadcrumb">
      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
      <Breadcrumb.Item><Link to="/categories">Categories</Link></Breadcrumb.Item>
    </Breadcrumb>
    <div className='tic-content-wrapper'>
      <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '20px 0' }}>
        <h3 className='tic-category-heading-title'>Viewing All Categories and Sub Categories</h3>
        <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
        >
          <Masonry>
            {
              categories?.map((category) => (
                <div style={{ margin: '10px' }}>
                  <CategoryCard category={category} showSubCategory />
                </div>
              ))
            }
          </Masonry>
        </ResponsiveMasonry>
        {
          categories?.length > 0 && (
            <Pagination
              className="mt-5"
              current={page}
              total={count}
              pageSize={limit}
              onChange={(page, pageSize) => {
                setPage(page);
                setLimit(pageSize);
              }}
              responsive
            />
          )
        }
      </Card>
    </div>
    </>
  );
};

export default CategoryPage;
