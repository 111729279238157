import { Breadcrumb, Card, message } from 'antd';
import axios from 'axios';
import { COMMON_URL, PAGE_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import './Cities.css';
import { Helmet } from 'react-helmet-async';
import CityCard from 'components/Cards/CityCard/CityCard';
import { groupBy, sortBy } from 'lodash';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Link } from 'react-router-dom';

const CityPage = () => {
  const [cities, setCities] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [seometadata, setSeometadata] = useState(null);

  useEffect(() => {
    onLoadSEO();
  }, []);

  const onLoadSEO = async () => {
    try {
      const res = await axios.get(`${PAGE_URL}/cities?type=slug`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setSeometadata(res.data.page?.seometadata);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    onLoadCity();
  }, []);

  const onLoadCity = async () => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${COMMON_URL}/business/cities`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        const _cities = sortBy(res.data.cities, 'name');
        const _citiesByTitle = groupBy(_cities, (city) => city?.name[0]?.toUpperCase());
        setCities(Object.keys(_citiesByTitle).map((title) => ({title, cities: _citiesByTitle[title]})));
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  return (
    <>
    <Helmet>
      <title>{seometadata?.title ? seometadata?.title : "All Cities | TN Industrial Connect"}</title>
      <meta name="description" content={seometadata?.description ? seometadata?.description : "Explore all business cities on TN Industrial Connect. Find verified businesses across various industries and sectors in Tamil Nadu."} />
      <meta name="keywords" content={seometadata?.keywords ? seometadata?.keywords : "Business cities, Tamil Nadu businesses, TN Industrial Connect Cities, Find businesses by city"} />
      {
        seometadata?.schema && (
          <script type="application/ld+json">
            {seometadata?.schema}
          </script>
        )
      }
    </Helmet>
    <Breadcrumb className="tic-breadcrumb">
      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
      <Breadcrumb.Item><Link to="/cities">Cities</Link></Breadcrumb.Item>
    </Breadcrumb>
    <div className='tic-content-wrapper'>
      <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '20px 0' }}>
        <h3 className='tic-city-heading-title'>Viewing All Cities</h3>
        <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
        >
            <Masonry>
              {
                cities?.map(({title, cities}) => (
                  <CityCard title={title} cities={cities} />
                ))
              }
            </Masonry>
        </ResponsiveMasonry>
      </Card>
    </div>
    </>
  );
};

export default CityPage;
