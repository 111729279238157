import React from 'react';
import { Card, Typography } from 'antd';

const { Text } = Typography;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Card bordered={false} style={{ backgroundColor: '#ffccc7', margin: '10px' }}>
          <Text>Something went wrong.</Text>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </Card>
      );
    }
    // Normally, just render children
    return <div>{this.props.children}</div>;
  }
}
export default ErrorBoundary;
