import React from 'react';
import { Card, Typography, Space, Row, Col, Image } from 'antd';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiChevronRight } from 'react-icons/fi';
import './CategoryCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';

const { Title } = Typography;

const CategoryCard = ({ category, showSubCategory }) => {
  const Base = showSubCategory ? React.Fragment: Link;
  const BaseRow = !showSubCategory ? React.Fragment: Link;
  return (
    <Base to={category.type === 'view-all' ? '/categories' : `/category/${category?.slug}`}>
      <Card
        className={`tic-category-card${showSubCategory ? ' subcategory' : ''}${category.type === 'view-all' ? ' tic-category-view-all' : ''}`}
      >
          <BaseRow to={`/category/${category?.slug}`}>
            <Row 
              justify="space-between" align="middle" className='tic-category-card-header'>
                <Col>
                    <Image src={category?.type === 'view-all' ? require('assets/images/icon-category.png'): `${UPLOAD_URL}/${category?.image}`} alt={category?.seometadata?.imageAlt || category?.name} title={category?.seometadata?.imageTitle || category?.name} preview={false} width={40} height={40} />
                </Col>
                <Col span={14}>
                    <Space direction="vertical" size={0}>
                        <Title level={5} className="tic-category-title">{category?.name}</Title>
                        {/* <Paragraph className="tic-category-desc">{category?.insights?.businesses} Businesses</Paragraph> */}
                    </Space>
                </Col>
                <Col>
                    <FiArrowRight style={{ fontSize: 24 }} />
                </Col>
            </Row>
          </BaseRow>
          {
            showSubCategory && (
              <Row>
                <Col span={24}>
                  <div className='tic-category-subcategory-list'>
                    {
                      category?.subcategories?.map((subcategory) => (
                        <Link to={`/category/${category?.slug}/${subcategory?.slug}`} className='tic-category-subcategory-item'>
                          <FiChevronRight />
                          <span>{subcategory?.name}</span>
                        </Link>
                      ))
                    }
                  </div>
                </Col>
              </Row>
            )
          }
      </Card>
    </Base>
  );
};

export default CategoryCard;
