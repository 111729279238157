import React from 'react';
import { Card, Typography, Row, Col, Image, Button, Avatar, Space, Divider } from 'antd';
import './ProductListingViewCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const INR = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumSignificantDigits: 3 });
const ProductListingCard = ({ listing, onEnquire }) => {
  const onSendEnquiry = () => {
    onEnquire(listing);
  };
  return (
    <Card
      className="tic-product-listing-card"
    >
      <Row justify="space-between" align="middle" gutter={[24, 24]}>
          {
            listing?.image && (
              <Col span={8}>
                <Image src={`${UPLOAD_URL}/${listing?.image}`} alt={listing?.seometadata?.imageAlt || listing?.name} title={listing?.seometadata?.imageTitle || listing?.name} preview={false} width="100%" className="tic-product-listing-image" />
              </Col>
            )
          }
          <Col span={16}>
            <Title level={1} className="tic-product-listing-title">
              {listing?.name}
            </Title>
            <Paragraph className="tic-product-listing-desc">
              {listing?.description}
            </Paragraph>
            <Paragraph className="tic-product-listing-address">
              {
                listing?.price && (
                  <div style={{ marginTop: 10 }}>
                    <Paragraph className="tic-product-listing-price"><span style={{ fontSize: 20 }}>{INR.format(listing.price || 0)}</span> / Piece</Paragraph>
                  </div>
                )
              }
            </Paragraph>
          </Col>
          <Col span={24}>
            <div>
            <Divider style={{ marginTop: 5, marginBottom: 20 }} />
              <Paragraph className="tic-product-listing-address">
                <Space>
                  <Image src={require('assets/images/icon-map-pin.png')} className='tic-product-listing-map-icon' preview={false} />
                  <span>
                    {listing?.business?.address?.city} - {listing?.business?.address?.state}
                  </span>
                </Space>
              </Paragraph>
              <Link to={`/business/${listing?.business?.slug}`}>
                <Space gap="middle" align="center" direction="horizontal" className="tic-product-listing-subtitle-container">
                  <Title level={5} className="tic-product-listing-subtitle">
                    <Avatar src={`${UPLOAD_URL}/${listing?.business?.logo}`} alt={listing?.seometadata?.logoAlt || listing?.name} title={listing?.seometadata?.logoTitle || listing?.name} className='tic-product-listing-logo' size="small" />
                    {listing?.business?.name}
                  </Title>
                </Space>
              </Link>
            </div>
          </Col>
          <Col span={24}>
            <Button type="primary" size="large" className="tic-product-listing-btn" onClick={onSendEnquiry}>
              Enquire
            </Button>
          </Col>
      </Row>
    </Card>
  );
};

export default ProductListingCard;
