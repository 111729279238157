import React, { useEffect, useState } from 'react';
import SearchListingComponent from './index';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PAGE_URL } from 'constants/ApiConstants';
import { message } from 'antd';
import axios from 'axios';

const SearchListingPage = () => {
  const [searchParams] = useSearchParams();
  const city = searchParams.get('city')?.split(',') ?? [];
  const searchText = searchParams.get('searchText');
  const category = searchParams.get('category')?.split(',') ?? [];
  const subCategories = searchParams.get('subcategory')?.split(',') || [];
  const [seometadata, setSeometadata] = useState(null);

  useEffect(() => {
    onLoadSEO();
  }, []);

  const onLoadSEO = async () => {
    try {
      const res = await axios.get(`${PAGE_URL}/search?type=slug`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setSeometadata(res.data.page?.seometadata);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>{seometadata?.title ? seometadata?.title : "Search Results | TN Industrial Connect"}</title>
        <meta name="description" content={seometadata?.description ? seometadata?.description : "Search results for businesses in Tamil Nadu on TN Industrial Connect. Find the businesses you are looking for with our comprehensive search feature."} />
        <meta name="keywords" content={seometadata?.keywords ? seometadata?.keywords : "search businesses, find businesses, Tamil Nadu business search, TN Industrial Connect search results"} />
        {
          seometadata?.schema && (
            <script type="application/ld+json">
              {seometadata?.schema}
            </script>
          )
        }
      </Helmet>
      <SearchListingComponent
        city={city}
        searchText={searchText}
        category={category}
        subCategory={subCategories}
        type="search"
      />
    </>
  );
};

export default SearchListingPage;
