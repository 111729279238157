import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Image, Button, Avatar, Space, Rate, message, Tag } from 'antd';
import './AboutCard.css';
import './ContactCard.css';
import { FiBookmark, FiLock, FiMail } from 'react-icons/fi';
import { UPLOAD_URL, USER_URL } from 'constants/ApiConstants';
import { map } from 'lodash';
import axios from 'axios';
import { EyeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ContactCard = ({ listing, unlocked }) => {
  return (
    <div className="tic-contact-card">
      <Row justify="space-between" align="middle">
          <Col span={24}>
            <Title level={5} className="tic-contact-title">
              Contact Details
            </Title>
            {
              !unlocked ? (
                <Image src={require('assets/images/blur-bg.png')} width='100%' preview={false} />
              ) : (
                <Row align="middle">
                  <Col>
                    <Paragraph className="tic-contact-info">
                      <Space>
                        <Image src={require('assets/images/icon-colored-email.png')} className='tic-contact-icon' />
                        <span>{listing?.emailAddress}</span>
                      </Space>
                    </Paragraph>
                    <Paragraph className="tic-contact-info">
                      <Space>
                        <Image src={require('assets/images/icon-colored-phone.png')} className='tic-contact-icon' />
                        <span>{listing?.phoneNumber}</span>
                      </Space>
                    </Paragraph>
                    {
                      listing?.website && (
                        <Paragraph className="tic-contact-info">
                          <Space>
                            <Image src={require('assets/images/icon-colored-website.png')} className='tic-contact-icon' />
                            <span>{listing?.website}</span>
                          </Space>
                        </Paragraph>
                      )
                    }
                    {
                      listing?.gstNumber && (
                        <Paragraph className="tic-contact-info">
                          <Space>
                            <Image src={require('assets/images/icon-colored-gstin.png')} className='tic-contact-icon' />
                            <span>{listing?.gstNumber}</span>
                          </Space>
                        </Paragraph>
                      )
                    }
                    <Paragraph className="tic-contact-info">
                      <Space>
                        <Image src={require('assets/images/icon-colored-location.png')} className='tic-contact-icon' />
                        <span>
                          {listing?.address?.line1},
                          {listing?.address?.line2 ? `${listing?.address?.line2},` : ''}<br />
                          {listing?.address?.city} - {listing?.address?.pincode}
                        </span>
                      </Space>
                    </Paragraph>
                  </Col>
                </Row>
              )
            }
          </Col>
      </Row>
    </div>
  );
};

const AboutCard = ({ loading, userId, unlocked, entityOwner, listing, onEnquire }) => {
  const rating = listing?.metadata?.totalRating ? listing?.metadata?.totalRating / listing?.metadata?.totalReviewers : 0;
  const reviews = listing?.metadata?.totalReviewers ?? 0;
  const [favLoading, setFavLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userId) {
      onLoadUser();
    }
  }, []);

  const onLoadUser = async () => {
    try {
      const res = await axios.get(`${USER_URL}/me`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setUser(res.data.user);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onToggleFavourite = async () => {
    setFavLoading(true);
    try {
      const res = await axios.put(`${USER_URL}/me`, {
        favourite: listing?._id,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        onLoadUser();
      }
    } catch (error) {
      message.error(error.message);
    }
    setFavLoading(false);
  };

  const onContactAdmin = () => {
    window.open(`mailto: contact@tnindustrialconnect.com?subject=Edit required for ${listing?.name}`);
  };

  const favouriteBusinesses = map(user?.favourites, 'business');
  const isFavourite = favouriteBusinesses.includes(listing?._id);
  return (
    <Card
      loading={loading}
      className="tic-about-card"
    >
      <Row justify="space-between" align="middle">
          {
            listing?.image && (
              <Col span={24} className="tic-about-image-wrapper">
                <Image src={`${UPLOAD_URL}/${listing?.image}`} preview={false} style={{ borderRadius: 10 }} alt={listing?.seometadata?.imageAlt || listing?.name} title={listing?.seometadata?.imageTitle || listing?.name} className="mb-3 tic-about-image" />
              </Col>
            )
          }
          <Col span={24}>
            <Title level={5} className="tic-about-title">
              <Avatar src={`${UPLOAD_URL}/${listing?.logo}`} alt={listing?.seometadata?.logoAlt || listing?.name} title={listing?.seometadata?.logoTitle || listing?.name} className='tic-about-logo' />
              {listing?.name}
            </Title>
            {
              listing?.analytics && (
                <Tag color="green"><EyeOutlined /> {listing?.analytics?.views} {listing?.analytics?.views > 1 ? 'views' : 'view'}</Tag>
              )
            }
            {
              listing?.description && (
                <Paragraph className="tic-about-desc">{listing?.description}</Paragraph>
              )
            }
            <Paragraph className="tic-about-address">
              <Space>
                <Image src={require('assets/images/icon-map-pin.png')} className='tic-about-map-icon' />
                <span>
                  {listing?.address?.line1},
                  {listing?.address?.line2 ? `${listing?.address?.line2},` : ''}<br />
                  {listing?.address?.city} - {listing?.address?.pincode}
                </span>
              </Space>
            </Paragraph>
            <Space gap="middle" className="tic-about-rating-container">
              <Rate disabled value={rating} size="small" />
              <span className="tic-about-rating-desc">{rating} from {reviews} reviews</span>
            </Space>
          </Col>
          <Col span={24}>
            <ContactCard listing={listing} unlocked={entityOwner || unlocked} />
            {
              (!entityOwner && !unlocked) && (
                <Button type="primary" size="large" className="tic-about-btn-enquiry" onClick={onEnquire}>
                  <div className="tic-btn-icon">
                    <FiLock />
                    <span>Send Enquiry</span>
                  </div>
                </Button>
              )
            }
            {
              (entityOwner) && (
                <div className="mt-3">
                  <Typography.Paragraph>
                    Would you like to make any changes to your contact information?
                  </Typography.Paragraph>
                  <Button type="primary" size="large" className="tic-about-btn-enquiry" onClick={onContactAdmin}>
                    <div className="tic-btn-icon">
                      <FiMail />
                      <span>Contact Admin</span>
                    </div>
                  </Button>
                </div>
              )
            }
            {
              user && (
                <Button type="secondary" size="large" className={`tic-about-btn-fav${isFavourite ? ' selected' : ''}`} onClick={onToggleFavourite} loading={favLoading}>
                    {
                      !isFavourite ? (
                        <div className="tic-btn-icon">
                          <FiBookmark />
                          <span>Add to Favourite</span>
                        </div>
                      ) : (
                        <div className="tic-btn-icon">
                          <FiBookmark />
                          <span>Remove from Favourite</span>
                        </div>
                      )
                    }
                </Button>
              )
            }
          </Col>
      </Row>
    </Card>
  );
};

export default AboutCard;
